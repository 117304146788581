import Image from "next/image";
import { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import './phInput.scss'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    icon: string;
    onChange: any;
    value: any;
}



const PhInput = (props: InputProps) => {

    const { name, icon, onChange, value } = props

    const [defCountry, setDefCountry] = useState("")

    useEffect(() => {
        fetch("https://ipapi.co/json")
            .then(res => res.json())
            .then(data => {
                setDefCountry(data.country_code.toLowerCase())
            })
            .catch(() => { setDefCountry("in") })
    }, [])

    return (
        <div className="form-input flex flex-row gap-2">
            <figure className="shrink-0">
                <Image loading="lazy"
                    src={icon}
                    alt={name}
                    className="avatar"
                    width={44}
                    height={44}
                />
            </figure>
            <div className="flex flex-col ">
                <label htmlFor="mobile" className="text-sm font-semibold">
                    {name}
                </label>

                {defCountry ?
                    <PhoneInput
                        inputClassName="rem-border"
                        name={name}
                        defaultCountry={defCountry}
                        // disableDialCodeAndPrefix
                        value={value}
                        onChange={onChange}
                        placeholder="99999 99999"
                        inputProps={{ id: "mobile" }}
                    /> : ""}

            </div>
        </div>
    );

};

export default PhInput 