import "@/styles/common/form.scss";
import { cn } from "@/utils/twMerge";
import Image from "next/image";

interface SelectProps {
    name: string;
    options: string[] | number[];
    icon: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    value: string | number;
    className?: string;
    placeholder: string;
}

const Select = (props: SelectProps) => {
    const { name, options, icon, onChange, value, className, placeholder } = props;
    return (
        <div className={cn("flex flex-row gap-1 single-form", className)}>
            <figure className="shrink-0">
                <Image loading="lazy"
                    src={icon}
                    alt={name}
                    className="avatar"
                    width={44}
                    height={44}
                />
            </figure>
            <div className="flex flex-col w-full select-wrapper">
                <label
                    className="font-semibold label text-sm ml-1"
                    htmlFor={name}
                >
                    {name}
                </label>
                <select
                    name={name}
                    className="select"
                    onChange={onChange}
                    value={value || options[0]}
                    // placeholder={placeholder}
                    id={name}
                >
                    {options.map((option, index) => {
                        return (
                            <option
                                key={index}
                                value={option}
                                disabled={index === 0}
                            >
                                {option}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    );
};

export default Select;
