import axios from "axios"

const pageMappings: any = {
    "HomePage": "home-pages",
    // SriLanka
    "SriLankaDestination": "sri-lanka-destinations",
    "SriLankaFamily": "sri-lanka-families",
    "SriLankaHoneymoon": "sri-lanka-honeymoons",
    // Thailand
    "ThailandDestination": "thailand-destinations",
    "ThailandFamily": "thailand-families",
    "ThailandHoneymoon": "thailand-honeymoons",
    // Vietnam 
    "VietnamDestination": "vietnam-destinations",
    "VietnamFamily": "vietnam-families",
    "VietnamHoneymoon": "vietnam-honeymoons",
    // Maldives
    "MaldivesDestination": "maldives-destinations",
    "MaldivesFamily": "maldives-families",
    "MaldivesHoneymoon": "maldives-honeymoons",
     // Bali
     "BaliDestination": "bali-destinations",
     "BaliFamily": "bali-families",
     "BaliHoneymoon": "bali-honeymoons",
     
     // Andaman
     "Andaman-Destination": "andaman-destinations",
     "Andaman-Family": "andaman-families",
     "Andaman-Honeymoon": "andaman-honeymoons",
      // Singapore
      "Singapore-Destination": "singapore-destinations",
      "Singapore-Family": "singapore-families",
      "Singapore-Honeymoon": "singapore-honeymoons",
      // Dubai
      "Dubai-Destination": "dubai-destinations",
      "Dubai-Family": "dubai-families",
      "Dubai-Honeymoon": "dubai-honeymoons",

      //Contact
      "ContactUs": "contact-uses",
      
      // Malaysia
      "Malaysia-Destination": "malaysia-destinations",
      "Malaysia-Family": "malaysia-families",
      "Malaysia-Honeymoon": "malaysia-honeymoons",

      // Hongkong
      "Hongkong-Destination": "hongkong-destinations",
      "Hongkong-Family": "hongkong-families",
      "Hongkong-honeymoon": "hongkong-honeymoons",


      // Malaysia
      "Thailand-Group-Tour": "thailand-group-tours",
      // Malaysia
      "Malaysia-Tour-Packages": "malaysia-tour-packages",

      // Thailand-from-chennai
      "Thailand-Tour-Package-From-Chennai": "thailand-tour-packages-from-chennais",

      // Thailand-from-kolkata
      "Thailand-Tour-Package-From-Kolkata": "thailand-tour-packages-from-kolkatas",

      // Thailand-from-mumbai
      "Thailand-Tour-Package-From-Mumbai": "thailand-tour-packages-from-mumbais",

      // Thailand-from-delhi
      "Thailand-Tour-Package-From-Delhi": "thailand-tour-packages-from-delhis",

      // Singapore-from-ahmedabad
      "Singapore-Tour-Package-From-Ahmedabad": "singapore-tour-packages-from-ahmedabads",

      // Singapore-from-chennai
      "Singapore-Tour-Package-From-Chennai": "singapore-tour-packages-from-chennais",

      // Singapore-from-mumbai
      "Singapore-Tour-Package-From-Mumbai": "singapore-tour-packages-from-mumbais",
      
      // Bali-from-chennai
      "Bali-Tour-Packages-from-Chennai": "bali-tour-packages-from-chennais",

      // Bali-from-mumbai
      "Bali-Tour-Packages-from-Mumbai": "bali-tour-packages-from-mumbais",

      // Bali-from-Kolkata
      "Bali-Tour-Packages-from-Kolkata": "bali-tour-packages-from-kolkatas",

      // Bali-from-Delhi
      "Bali-Tour-Packages-from-Delhi": "bali-tour-packages-from-delhis",

      // Malaysia-from-chennai
      "Malaysia-Tour-Packages-from-Chennai": "malaysia-tour-packages-from-chennais",

      // Malaysia-from-mumbai
      "Malaysia-Tour-Packages-from-Mumbai": "malaysia-tour-packages-from-mumbais",

      // Malaysia-from-Kolkata
      "Malaysia-Tour-Packages-from-Kolkata": "malaysia-tour-packages-from-kolkatas",

      // Malaysia-from-Delhi
      "Malaysia-Tour-Packages-from-Kerala": "malaysia-tour-packages-from-keralas",

       // Malaysia-from-
       "Malaysia-Tour-Packages-from-Bangalore": "malaysia-tour-packages-from-bangalores",

       // Vietnam-from-chennai
      "Vietnam-Tour-Packages-from-Chennai": "vietnam-tour-packages-from-chennais",

      // Vietnam-from-mumbai
      "Vietnam-Tour-Packages-from-Mumbai": "vietnam-tour-packages-from-mumbais",

      // Vietnam-from-Kolkata
      "Vietnam-Tour-Packages-from-Kolkata": "vietnam-tour-packages-from-kolkatas",

      // Malaysia-from-Delhi
      "Vietnam-Tour-Packages-from-Kerala": "vietnam-tour-packages-from-keralas",

       // Malaysia-from-
       "Vietnam-Tour-Packages-from-Bangalore": "vietnam-tour-packages-from-bangalores",

      
}

const submitFormDetails =  (data: any, PageName: string) => {
    return  cmsService(data, PageName);
}

const cmsService = async (data: any, PageName: string) => {
    const config = {
        headers: { Authorization: `bearer b1937cc7ed9d01757e3e2f94f117423b3023f6b42beb3e3a6db11b6bf61a9340960a0af6596f0894ec9116ae4801bb197fa8558b2bdc01fc7e486217d8f2ca44b1ab796327c8ce4a75a4c877bd25be98367bb7329babb7b196480c6b561d8f0088c4b3a27aab902107c5412cefc7074a0ad1bb10cdefc34fbbd1a263c8bf29fa` }
    }
    try {
        const response = await axios.post(`https://cms.dimaak.com/api/${pageMappings[PageName]}`, { "data": data }, config)
        return true;
    } catch (error) {
        console.log("error: ", error)
        return false;
    }
};



export default submitFormDetails;



