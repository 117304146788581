import Modal from "react-modal";
import styles from "./VideoModal.module.scss"; // Import CSS module

interface VideoModalProps {
    isOpen: boolean;
    onClose: () => void;
    videoId?: string;
    videoTitle?: string;
}

const VideoModal: React.FC<VideoModalProps> = ({
    isOpen,
    onClose,
    videoId,
    videoTitle,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Video Modal"
            className={styles.modal}
            overlayClassName={styles.overlay}
        >
            <div>
                <div className={styles.closeButton}>
                    <button onClick={onClose}>
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <ellipse
                                cx="14.375"
                                cy="15"
                                rx="9.375"
                                ry="9"
                                fill="#FF4514"
                            />
                            <path
                                d="M14.5 3C7.888 3 2.5 8.388 2.5 15C2.5 21.612 7.888 27 14.5 27C21.112 27 26.5 21.612 26.5 15C26.5 8.388 21.112 3 14.5 3ZM18.532 17.76C18.88 18.108 18.88 18.684 18.532 19.032C18.352 19.212 18.124 19.296 17.896 19.296C17.668 19.296 17.44 19.212 17.26 19.032L14.5 16.272L11.74 19.032C11.56 19.212 11.332 19.296 11.104 19.296C10.876 19.296 10.648 19.212 10.468 19.032C10.12 18.684 10.12 18.108 10.468 17.76L13.228 15L10.468 12.24C10.12 11.892 10.12 11.316 10.468 10.968C10.816 10.62 11.392 10.62 11.74 10.968L14.5 13.728L17.26 10.968C17.608 10.62 18.184 10.62 18.532 10.968C18.88 11.316 18.88 11.892 18.532 12.24L15.772 15L18.532 17.76Z"
                                fill="white"
                            />
                        </svg>
                    </button>
                </div>
                <iframe
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title={videoTitle}
                    allowFullScreen
                ></iframe>
            </div>
        </Modal>
    );
};

export default VideoModal;
